<template>
  <div class="mainContainer" :class="{dark: darkTheme}">
    <div class="controlsBar">
      <div>
        <button class="controlButton" @click="toggleTheme()">{{ darkTheme ? "☀️" : "🌒" }}</button>
        <button class="controlButton" :disabled="!hasPinnedItems" @click="clearPinnedItems()">⭕️</button>
        <button class="controlButton" @click="showAll()">{{ showAllItems ? '🔴' : '🔵' }}</button>
        <button class="controlButton" @click="download()">📄</button>
        <button class="controlButton" @click="toggleHelp()">❓</button>
        <input ref="emailInput" type="text">
        <button class="controlButton" @click="login()">🚪</button>
        <button class="controlButton" @click="getNotes()">⬇️</button>
        <button class="controlButton" @click="updateNotes()">⬆️</button>
        <button class="controlButton" @click="toggleDebug()">🐛</button>
      </div>
      <div v-if="showHelp">
        <pre>General Notes:
  pages: items prefixed with `$ ` (note the space) are considered "pages".
    Pages are automatically collapsed when not selected. You can pin them open with "pinning"
  pinning: you can pin items to remain visible by clicking the open circle next to it. Click the filled
    circle to unpin the item or click ⭕️ to unpin all items.

Keyboard Controls:
  enter: new item
  tab: increase item indentation
  tab + shift: decrease item indentation
  up/down arrow: move cursor to item above/below
  up/down arrow + meta (command or windows key): move cursor to sibling item above/below
  up/down arrow + alt/option: move item up/down in sibling list
  backspace + shift: if an item is empty and these keys are pressed, it deletes the item
    and ALL descendants (children and children)! Be careful!
  [on a page] meta (command or windows key) + click: set that page as the "root"
  [on a page] meta (command or windows key) + enter: set that page as the "root"
  ctrl + minus ("-"): set parent page as "root"
        </pre>
      </div>
      <div>
        <span v-for="(itemId, index) in pagePathItemIds" :key="index">
          <a
            href="#"
            @click="setCurrentRootId(itemId)"
            class="rootLink"
            :class="{activeRootLink: currentRootId == itemId}"
          >{{ itemId == ROOT ? 'Home' : treeData[itemId].name }}</a>
          <span v-if="index != pagePathItemIds.length - 1">></span>
        </span>
      </div>
    </div>
    <ul id="demo">
      <treeItem
        class="item"
        v-for="(childId, index) in treeData[currentRootId].children"
        :key="index"
        v-bind="treeData[childId]"
        :id="childId"
        :depth="0"></treeItem>
    </ul>
    <div v-if="debug">
      <p>
        Active Item:
        <pre>{{ activeItemId }}: {{ treeData[activeItemId] }}</pre>
      </p>
      <p>
        Full Tree:
        <pre>{{ treeData }}</pre>
      </p>
    </div>
  </div>
</template>

<script>
import treeItem from './components/TreeItem.vue'
import { mapState } from 'vuex'
import { ROOT } from './main'

export default {
  name: 'App',
  components: {
    treeItem
  },
  data: () => ({
    ROOT,
    debug: false,
    showHelp: false,
  }),
  computed: {
    hasPinnedItems() {
      return this.pinnedItems.length > 0
    },
    pagePathItemIds() {
      const ids = []
      let itemId = this.activeItemId
      while (itemId) {
        const item = this.treeData[itemId]
        // TODO: create util for checking if an item is a page and use here as well as treeitem component
        if (item.name.startsWith('$ ') || itemId == ROOT) {
          ids.push(itemId)
        }

        itemId = item.parentId
      }

      return ids.reverse()
    },
    ...mapState(['treeData', 'activeItemId', 'pinnedItems', 'showAllItems', 'darkTheme', 'currentRootId'])
  },
  methods: {
    login: async function() {
      const email = this.$refs.emailInput.value
      const data = { email }
      const response = await fetch(
        '/.netlify/functions/login',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        }
      ).then(res => res.json())

      alert('Check your email...')
    },
    getNotes: async function () {
      console.log('getting...')
      const response = await fetch(
        '/.netlify/functions/notes',
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        }
      ).then(res => res.json())

      this.$store.commit('setCurrentRoot', { id: ROOT })
      this.$store.commit('setTreeData', { treeData: response.data })
      alert('Successfully pulled data...')
    },
    updateNotes: async function() {
      const response = await fetch(
        '/.netlify/functions/notes',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ data: this.treeData })
        }
      ).then(res => res.json())

      alert('Successfully pushed data...')
    },
    toggleTheme: function() {
      this.$store.commit('setDarkTheme', !this.darkTheme)
    },
    clearPinnedItems: function() {
      this.$store.commit('clearPinnedItems')
    },
    showAll: function() {
      this.$store.commit('toggleShowAllItems')
    },
    setCurrentRootId: function(itemId) {
      const item = this.treeData[itemId]
      if (item.name.startsWith('$ ') || itemId == ROOT) {
        this.$store.commit('setCurrentRoot', { id: itemId })
      }
    },
    toggleDebug: function () {
      this.debug = !this.debug
    },
    toggleHelp: function() {
      this.showHelp = !this.showHelp
    },
    toString: function() {
      // walk the tree, in order, adding the text as we go
      const stringifyTree = (nodeId, depth) => {
        const paddingUnit = '  '
        const linePrefix = '- '
        const thisItem = this.treeData[nodeId]
        // const thisItemLinesIndented = thisItem.name.split('\n')
          // .join(`\n${paddingUnit.repeat(depth)}${' '.repeat(linePrefix.length)}`)
        const thisItemLinesIndented = thisItem.name.replaceAll('\n-', '\n\\-')
        
        const thisItemStr = `${paddingUnit.repeat(depth)}${linePrefix}${thisItemLinesIndented}`
        let thisTreeStr = thisItemStr
        for (let childId of thisItem.children) {
          thisTreeStr += `\n${stringifyTree(childId, depth + 1)}`
        }

        return thisTreeStr
      }

      let finalTree = ''
      for (let childId of this.treeData[ROOT].children) {
        finalTree += `\n${stringifyTree(childId, 0)}`
      }
      return finalTree
    },
    download: function() {
      const element = document.createElement('a')
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.toString()));
      const currentDatetime = new Date().toLocaleString()
      const fileName = `notes_${currentDatetime}`.replaceAll('/', '-').replaceAll(' ', '_').replaceAll(',', '').replaceAll(':', '-')
      element.setAttribute('download', fileName);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  }
}
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
}

body {
  font-family: Menlo, Consolas, monospace;
  background-color: white;
  color: rgb(32, 32, 32);
}

.mainContainer {
  overflow: scroll;
}

.controlsBar {
  background-color: inherit;
  position: sticky;
  top: 0;
}

.item {
  cursor: pointer;
}

ul {
  padding-left: 2em;
  list-style-type: circle;
}

li > * {
    vertical-align: text-top;
}

textarea {
  background-color: inherit;
  color: inherit;
}

.dark {
  height: 100%;
  background-color: #444;
  color: white;
}

.controlButton {
  padding: 3px;
  font-size: 2em;
  border: none;
  background: none;
  cursor: pointer;
}

.controlButton:disabled {
  opacity: 0.5;
  cursor: default;
}

.rootLink {
  color: inherit;
}

.activeRootLink {
  text-decoration: red underline;
}

</style>
