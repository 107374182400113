<template>
  <li @click.stop="togglePinned($event)" :class="{pinned: pinned}">
    <textarea
      contenteditable
      ref="content"
      :value="name"
      :class="{page: isPage, activeItem: activeItemId == id, activePage: isActivePage, itemContent: true}"
      :style="{width: LINE_LENGTH + 'ch', height: numberOfLines + 0.5 + 'em' }"
      v-bind:id="id"
      @focus.stop="selectItem()"

      @click.meta="setCurrentRoot(id, true)"
      @keydown.meta.enter.prevent
      @keydown.meta.enter="setCurrentRoot(id, true)"

      @keydown.ctrl.189="setRootParentAsRoot()"

      @keydown.enter.exact.prevent
      @keyup.enter.exact.prevent="addItem()"

      @keydown.tab.exact.prevent
      @keyup.tab.exact.prevent="indent()"

      @keydown.shift.tab.exact.prevent
      @keyup.shift.tab.exact.prevent="dedent()"

      @keydown.backspace.exact="maybeRemove($event)"
      @keydown.shift.backspace.exact="maybeRemove($event, true)"

      @keydown.up.exact.prevent="moveCursorUp()"
      @keydown.down.exact.prevent="moveCursorDown()"

      @keydown.up.meta.exact.prevent="moveCursorUpDownSibling(-1)"
      @keydown.down.meta.exact.prevent="moveCursorUpDownSibling(1)"

      @keydown.up.alt.exact.prevent="moveItemUp()"
      @keydown.down.alt.exact.prevent="moveItemDown()"

      @input='setThisName'></textarea>
    <ul v-show="showItemChildren" v-if="hasChildren" :class="colorClasses[depth % colorClasses.length]">
      <treeItem
        class="item"
        v-for="(childId, index) in children"
        :key="index"
        v-bind="treeData[childId]"
        :id="childId"
        :depth="depth + 1"
      ></treeItem>
    </ul>
  </li>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { ROOT } from '../main';
// import { ROOT } from '../main';

export default {
  name: 'treeItem',
  props: {
    id: String,
    parentId: String,
    name: String,
    children: Array,
    depth: Number,
  },
  data: function () {
    return {
      LINE_LENGTH: 80,
      colorClasses: ['violet', 'blue', 'green', 'yellow', 'orange', 'red'],
    };
  },
  computed: {
    hasChildren: function () {
      return this.itemHasChildren(this.id)
    },
    pinned: function () {
      return this.pinnedItems.indexOf(this.id) >= 0
    },
    showItemChildren: function () {
      if (!this.isPage || this.showAllItems) {
        return true
      }

      // show children if any of these things are true:
      // - this item is the active item
      // - it's an ancestor to the active item
      // - it's a pinned item
      // - it's an ancestor to a pinned item
      // const openIds = [this.activePageId, ...this.pinnedItems]
      const openIds = [this.activeItemId, ...this.pinnedItems]

      for (let openId of openIds) {
        let itemId = openId
        while (itemId != this.currentRootId) {
          if (itemId == this.id) {
            // found an active or pinned item 
            return true
          }

          itemId = this.treeData[itemId].parentId
        }
      }

      return false
    },
    isActivePage: function () {
      if (!this.itemIsPage(this.id)) {
        return false
      }

      // if we are the first page ancestor to the active item, we are an active page
      let itemId = this.activeItemId
      while (itemId != this.currentRootId) {
        if (this.itemIsPage(itemId)) {
          // found first page ancestor, return true if we are that page we found
          return itemId == this.id
        }

        itemId = this.treeData[itemId].parentId
      }

      return false
    },
    isPage: function () {
      return this.itemIsPage(this.id)
    },
    numberOfLines: function () {
      // number of lines = number newlines + linewraps
      let lines = this.name.split('\n')
      let numLines = lines.length
      lines.forEach(line => {
        numLines += Math.floor(Math.max(0, line.length - 1) / this.LINE_LENGTH)
      })

      return Math.max(numLines, 1)
    },
    ...mapState(['treeData', 'activePageId', 'activeItemId', 'pinnedItems', 'showAllItems', 'currentRootId'])
  },
  methods: {
    moveCursorUp: function () {
      const itemAboveId = this.getItemAboveId(this.id)
      if (itemAboveId && itemAboveId != this.currentRootId) {
        this.$store.commit('setActiveItem', { id: itemAboveId })
         this.$nextTick(() => {
          this.setCaretPosition(document.querySelector(`#${itemAboveId}`), 0)
        })
      }
    },
    moveCursorDown: function () {
      const itemBelowId = this.getItemBelowId(this.id)
      if (itemBelowId && itemBelowId != this.currentRootId) {
        this.$store.commit('setActiveItem', { id: itemBelowId })
         this.$nextTick(() => {
          this.setCaretPosition(document.querySelector(`#${itemBelowId}`), -1)
        })
      }
    },
    moveCursorUpDownSibling: function(relativeShift) {
      const thisItem = this.treeData[this.id]
      const parentItem = this.treeData[thisItem.parentId]
      const thisIndex = parentItem.children.indexOf(this.id)

      const newActiveItemIndex = Math.max(0, Math.min(thisIndex + relativeShift, parentItem.children.length - 1))
      const newActiveItemId = parentItem.children[newActiveItemIndex]

      if (newActiveItemId != this.id) {
        this.$store.commit('setActiveItem', { id: newActiveItemId })
        this.$nextTick(() => {
          this.setCaretPosition(document.querySelector(`#${newActiveItemId}`), -1)
        })
      }
    },
    togglePinned: function (e) {
      // only pin if they clicked directly on the LI element
      if (e.target.nodeName == "LI") {
        this.$store.commit('togglePinned', { id: this.id })
      }
    },
    itemIsPage: function (id) {
      return this.treeData[id].name.startsWith('$ ')
    },
    itemHasChildren: function (id) {
      return this.treeData[id].children && this.treeData[id].children.length
    },
    getItemAboveId: function (id) {
      // get the id of the item visually above
      // find by walking the tree in order (visiting non-terminal nodes before terminal ones)
      // the node visited immediately prior to the target node is the one thats visually right above it
      const findNodePrior = (itemId, lastVisitedId) => {
        if (itemId == id) {
          return { foundId: lastVisitedId }
        }

        const thisItem = this.treeData[itemId]
        let lastId = itemId
        let foundId = undefined
        for (let childId of thisItem.children) {
          ({ foundId, lastId } = findNodePrior(childId, lastId))
          if (foundId) {
            return { foundId }
          }
        }

        // failed to find id in the subtree
        return { lastId }
      }

      const { foundId } = findNodePrior(this.currentRootId, null)
      return foundId
    },
    getItemBelowId: function (id) {
      const findNodeNext = (itemId, returnNextItem) => {
        if (returnNextItem) {
          return { foundId: itemId }
        }

        let returnNext = false
        if (itemId == id) {
          returnNext = true
        }

        // go through child subtrees
        let foundId = null
        const thisItem = this.treeData[itemId]
        for (let childId of thisItem.children) {
          ({ foundId, returnNext } = findNodeNext(childId, returnNext))
          if (foundId) {
            return { foundId }
          }
        }

        // failed to find id in this node or subtree
        return { returnNext }
      }

      const { foundId } = findNodeNext(this.currentRootId, false)
      return foundId
    },
    maybeRemove: function (event, removeAllDescendants) {

      const isLastItem = this.treeData[this.currentRootId].children.length == 1 && this.treeData[this.currentRootId].children[0] == this.id
      if (this.name.length || isLastItem) {
        return
      }

      event.preventDefault()

      const itemAboveId = this.getItemAboveId(this.id)

      if (removeAllDescendants) {
        const removeTree = (itemId) => {
          // using slice() here to avoid weird condition where children aren't removed
          // I think the weird stuff was happening b/c we are iterating and modifying children at the same time...
          this.treeData[itemId].children.slice().forEach(childId => {
            removeTree(childId)
          })

          this.$store.commit('removeItem', { id: itemId })
        }

        removeTree(this.id)
      } else {
        // move children
        this.children.slice().reverse().forEach(childId => {
          this.$store.commit('dedentItem', { id: childId })
        })

        this.$store.commit('removeItem', { id: this.id })
      }

      // focus on item above
      this.$store.commit('setActiveItem', { id: itemAboveId })
      if (itemAboveId != this.currentRootId) {
        this.$nextTick(() => {
          this.setCaretPosition(document.querySelector(`#${itemAboveId}`), -1)
        })
      }
    },
    selectItem: function () {
      this.$store.commit('setActiveItem', { id: this.id })

      // update active page (deprecate...)
      // let itemId = this.id
      // while (itemId != this.currentRootId && !this.itemIsPage(itemId)) {
      //   itemId = this.treeData[itemId].parentId
      // }
      // this.$store.commit('setActivePage', { id: itemId })
    },
    setThisName: function () {
      this.$store.commit('setItemName', { id: this.id, name: this.$refs.content.value })
      // if (this.isPage && this.activePageId != this.id) {
      //   this.$store.commit('setActivePage', { id: this.id })
      // }
    },
    setCurrentRoot: function (itemId, focusOnFirstChild) {
      if (this.itemIsPage(itemId) || itemId == ROOT) {
        this.$store.commit('setCurrentRoot', { id: itemId })
      }
      if (focusOnFirstChild && this.itemHasChildren(itemId)) {
        const firstChildId = this.children[0]
        Vue.nextTick(() => this.setCaretPosition(document.querySelector(`#${firstChildId}`), 0))
      }
    },
    setRootParentAsRoot: function () {
      const parentId = this.treeData[this.currentRootId].parentId
      console.log('hello', parentId)
      if (parentId != null) {
        this.setCurrentRoot(parentId, false)
      }
    },
    addItem: function () {
      const id = `i${Date.now()}`
      const ref = this.$refs.content
      const cursor = ref.selectionStart
      const newItemName = ref.value.substr(cursor)

      // remove everything after the cursor from the current item
      this.$store.commit('setItemName', { id: this.id, name: ref.value.substr(0, cursor) })

      if (this.children.length > 0) {
        this.$store.commit('addItem', { parentId: this.id, index: 0, id, name: newItemName })
      } else {
        const thisIndex = this.treeData[this.parentId].children.indexOf(this.id)
        this.$store.commit('addItem', { parentId: this.parentId, index: thisIndex + 1, id, name: newItemName})
      }
      Vue.nextTick(() => this.setCaretPosition(document.querySelector(`#${id}`), 0))
    },
    setCaretPosition(elem, pos) {
      if (pos == -1) {
        pos = elem.value.length
      }

      elem.focus();
      elem.setSelectionRange(pos, pos);
    },
    indent() {
      const id = this.id
      const ref = this.$refs.content
      const cursor = ref.selectionStart

      this.$store.commit('indentItem', { id: this.id} )

      this.$nextTick(() => {
        // not sure why, but using refs instead of queryselectors doesnt seem to work...
        this.setCaretPosition(document.querySelector(`#${id}`), cursor)
      })
    },
    dedent: function() {
      const id = this.id
      const ref = this.$refs.content
      const cursor = ref.selectionStart

      this.$store.commit('dedentItem', { id: this.id})

      this.$nextTick(() => {
        // not sure why, but using refs instead of queryselectors doesnt seem to work...
        this.setCaretPosition(document.querySelector(`#${id}`), cursor)
      })
    },
    moveItemUp: function() {
      const id = this.id
      const ref = this.$refs.content
      const cursor = ref.selectionStart

      this.$store.commit('shiftItem', { id: this.id, relativeShift: -1 })

      this.$nextTick(() => {
        // not sure why, but using refs instead of queryselectors doesnt seem to work...
        this.setCaretPosition(document.querySelector(`#${id}`), cursor)
      })
    },
    moveItemDown: function() {
      const id = this.id
      const ref = this.$refs.content
      const cursor = ref.selectionStart

      this.$store.commit('shiftItem', { id: this.id, relativeShift: 1 })

      this.$nextTick(() => {
        // not sure why, but using refs instead of queryselectors doesnt seem to work...
        this.setCaretPosition(document.querySelector(`#${id}`), cursor)
      })
    }
  }
}
</script>

<style scoped>
textarea {
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
  resize: none;
  border-style: none;
  padding: 0;
}
.itemContent:focus {
  outline: none;
}

.page {
  font-weight: bold;
  text-decoration: underline overline #ffdf28;
}

.page.activePage {
  text-decoration: underline overline #FF3028;
}

ul {
  border-left-style: solid;
}

.pinned {
  list-style-type: disc;
}

.red {
  border-left-color: #FFB1B0;
}

.orange {
  border-left-color: #FFDFBE;
}

.yellow {
  border-left-color: #FFFFBF;
}

.green {
  border-left-color: #B4F0A7;
}

.blue {
  border-left-color: #A9D1F7;
}

.violet {
  border-left-color: #CC99FF;
}
</style>
