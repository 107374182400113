import Vue from 'vue'
import App from './App.vue'

import Vuex from 'vuex'

Vue.config.productionTip = false

Vue.use(Vuex)

export const ROOT = "__ROOT__"

const defaultTree = {
  [ROOT]: { name: 'root', children: ['i1', 'i2'], parentId: null},
  'i1': { name: 'foo', children: [], parentId: ROOT},
  'i2': { name: '$ bar', children: ['i3'], parentId: ROOT},
  'i3': { name: 'hello!', children: [], parentId: 'i2'}
};

const STORAGE_KEY = 'treeData'

const loadItems = () => {
  let storedData = JSON.parse(localStorage.getItem(STORAGE_KEY))
  if (!storedData) {
    storedData = defaultTree
  }

  if (storedData[ROOT].children.length == 0) {
    const id = `i${Date.now()}`
    storedData[ROOT].children.push(id)
    storedData[id] = { name: '', children: [], parentId: ROOT }
  }

  return storedData
}

const saveItems = (treeData) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(treeData))
}

const treeData = loadItems()

const store = new Vuex.Store({
  state: {
    treeData,
    activePageId: ROOT,
    activeItemId: ROOT,
    currentRootId: ROOT,
    pinnedItems: [],
    showAllItems: false,
    darkTheme: true,
  },

  mutations: {
    togglePinned(state, { id }) {
      const itemIndex = state.pinnedItems.indexOf(id)
      if (itemIndex >= 0) {
        state.pinnedItems.splice(itemIndex, 1)
      } else {
        state.pinnedItems.push(id)
      }
    },
    clearPinnedItems(state) {
      state.pinnedItems.splice(0)
    },
    toggleShowAllItems(state) {
      state.showAllItems = !state.showAllItems
    },
    setActivePage(state, { id }) {
      state.activePageId = id
    },
    setActiveItem(state, { id }) {
      state.activeItemId = id
    },
    setCurrentRoot(state, { id }) {
      state.currentRootId = id
    },
    setTreeData(state, { treeData }) {
      Vue.set(state, 'treeData', treeData)
    },
    setDarkTheme(state, isDark) {
      state.darkTheme = isDark
    },
    addItem(state, { parentId, index, id, name }) {
      Vue.set(state.treeData, id, {
        name: name || '',
        parentId,
        children: [],
      })

      state.treeData[parentId].children.splice(index, 0, id)
    },
    removeItem(state, { id }) {
      const parentItem = state.treeData[state.treeData[id].parentId]
      const itemIndex = parentItem.children.indexOf(id)
      parentItem.children.splice(itemIndex, 1)

      const itemPinIndex = state.pinnedItems.indexOf(id)
      if (itemPinIndex >= 0) {
        state.pinnedItems.splice(itemPinIndex, 1)
      }
  
      Vue.delete(state.treeData, id);
    },
    setItemName(state, { id, name }) {
      const item = state.treeData[id]
      item.name = name
    },
    indentItem(state, { id }) {
      const thisItem = state.treeData[id]
      const parentItem = state.treeData[thisItem.parentId]
      const thisItemIndex = parentItem.children.indexOf(id)
      const prevSiblingIndex = thisItemIndex - 1
      const prevSiblingId = parentItem.children[prevSiblingIndex]
      const prevSiblingItem = state.treeData[prevSiblingId]
      if (!prevSiblingItem) {
        return
      }

      // move to the end of the sibling's children
      thisItem.parentId = prevSiblingId
      prevSiblingItem.children.push(id)
      parentItem.children.splice(thisItemIndex, 1)
    },
    dedentItem(state, { id }) {
      const thisItem = state.treeData[id]

      const parentId = thisItem.parentId
      const parentItem = state.treeData[parentId]
  
      const grandparentId = parentItem.parentId
      const grandparentItem = state.treeData[grandparentId]
  
      if (!grandparentItem) {
        return
      }
  
      // move item to the grandparent, right after its original parent
      thisItem.parentId = grandparentId
  
      const parentIndex = grandparentItem.children.indexOf(parentId)
      grandparentItem.children.splice(parentIndex + 1, 0, id)
  
      const thisIndex = parentItem.children.indexOf(id)
      parentItem.children.splice(thisIndex, 1)
    },
    shiftItem(state, { id, relativeShift }) {
      const thisItem = state.treeData[id]
      const parentItem = state.treeData[thisItem.parentId]
      const originalIndex = parentItem.children.indexOf(id)
      const newIndex = Math.max(0, originalIndex + relativeShift)
      parentItem.children.splice(originalIndex, 1)
      parentItem.children.splice(newIndex, 0, id)
    }
  },
})

// Save jots to localStorage on change
store.subscribe((mutation, state) => {
	saveItems(state.treeData)
});

new Vue({
  render: h => h(App),
  store,
}).$mount('#app')
